var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer-nav__text" }, [
    _c("div", { staticClass: "mb-3" }, [
      _c("strong", [
        _vm._v(_vm._s(_vm.$t("factories.akmenynaiFactory.title"))),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("factories.akmenynaiFactory.address")) + ", LT-69300,"
        ),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("factories.akmenynaiFactory.location")))]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("generic.coordinates")) + ":\n      "
        ),
        _c("i", { staticClass: "fas fa-map-marker-alt mr-1 text-green" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-green",
            attrs: {
              href: "http://maps.google.com/maps/ms?ie=UTF&msa=0&msid=202175584425752091004.0004afb57c09a69a75c5e&z=15&hl=en",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [_vm._v("\n        54.409841,23.093817\n      ")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-3" }, [
      _c("strong", [_vm._v(_vm._s(_vm.$t("factories.jureFactory.title")))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("factories.jureFactory.address")) + ",")]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("factories.jureFactory.location")))]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("generic.coordinates")) + ":\n      "
        ),
        _c("i", { staticClass: "fas fa-map-marker-alt mr-1 text-green" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-green",
            attrs: {
              href: "http://maps.google.com/maps/ms?ie=UTF&msa=0&msid=202175584425752091004.0004afb4b263a8992478b&z=15&hl=en",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [_vm._v("\n        54.767919,23.485873\n      ")]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("factories.woodAcceptanceTime")) + ": I - V 8:00-17:00"
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-3" }, [
      _c("strong", [
        _vm._v(_vm._s(_vm.$t("factories.radviliskisFactory.title"))),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.$t("factories.radviliskisFactory.address")) + ","),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.$t("factories.radviliskisFactory.location"))),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("generic.coordinates")) + ":\n      "
        ),
        _c("i", { staticClass: "fas fa-map-marker-alt mr-1 text-green" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-green",
            attrs: {
              href: "https://mapsengine.google.com/map/viewer?mid=zLULJGrDD0Tw.k_JKW87O9qds&z=15&hl=en",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [_vm._v("\n        55.820092,23.519006\n      ")]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("factories.woodAcceptanceTime")) + ": I - V 6:00-19:00"
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-3" }, [
      _c("strong", [
        _vm._v(_vm._s(_vm.$t("factories.marijampoleFactory.title"))),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.$t("factories.marijampoleFactory.address")) + ","),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.$t("factories.marijampoleFactory.location"))),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("generic.coordinates")) + ":\n      "
        ),
        _c("i", { staticClass: "fas fa-map-marker-alt mr-1 text-green" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-green",
            attrs: {
              href: "https://goo.gl/maps/LonCHWFo25amZC4H9",
              rel: "noopener noreferrer",
              target: "_blank",
            },
          },
          [_vm._v("\n        54.586296,23.412613\n      ")]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("factories.woodAcceptanceTime")) + ": I - V 6:00-21:00"
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.$root.isLVA
      ? _c("div", { staticClass: "mb-3" }, [
          _c("strong", [
            _vm._v(_vm._s(_vm.$t("factories.iksķilesFactory.title"))),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("factories.iksķilesFactory.address")) + ","),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("factories.iksķilesFactory.location"))),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("generic.coordinates")) + ":\n      "
            ),
            _c("i", { staticClass: "fas fa-map-marker-alt mr-1 text-green" }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "text-green",
                attrs: {
                  href: "https://maps.app.goo.gl/4dDUfAkd8okBWLL8A",
                  rel: "noopener noreferrer",
                  target: "_blank",
                },
              },
              [_vm._v("\n        56.847263, 24.507117\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("factories.woodAcceptanceTime")) +
                ": I - V 8:00-17:00"
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }