import Es6Promise from 'es6-promise';
import 'babel-polyfill';
import 'url-search-params-polyfill';
import '../sass/style.scss';
import axiosApi from 'axios';
import { directive as onClickaway } from 'vue-clickaway';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import router from './app/router';
import App from './app/components/App.vue';
import lt from '../locales/lt.json';
import lv from '../locales/lv.json';

Es6Promise.polyfill();

const axios = axiosApi.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

// Use the window object to make it available globally.
window.axios = axios;

Vue.directive('on-clickaway', onClickaway);
Vue.directive('to-lowercase', {
  update(el) {
    // eslint-disable-next-line no-param-reassign
    el.value = el.value.toLowerCase();
  },
});

Vue.use(VueI18n);

const messages = { lt, lv };
const isoLang = {
  LVA: 'lv',
  LTU: 'lt',
};

const i18n = new VueI18n({
  locale: isoLang[siteLang],
  messages,
});

new Vue({
  el: '#app',
  i18n,
  router,
  components: { App },
  data: {
    isAuth: false,
    isAdmin: false,
    isLTU: false,
    isLVA: false,
    siteLanguageKey: isoLang[siteLang],
    email: {
      lt: 'info@juodeliaiperka.lt',
      lv: 'info@perkukoksni.lv ',
    },
    notCertifiedWoodDiscount: 2,
    fastPaymentDiscount: 2,
  },
  template: '<App/>',
});
