<template>
  <div class="drawer-nav__text">
    <div class="mb-3">
      <strong>{{ $t('factories.akmenynaiFactory.title') }}</strong>
      <p>{{ $t('factories.akmenynaiFactory.address') }}, LT-69300,</p>
      <p>{{ $t('factories.akmenynaiFactory.location') }}</p>
      <p>
        {{ $t('generic.coordinates') }}:
        <i class="fas fa-map-marker-alt mr-1 text-green"></i>
        <a
          href="http://maps.google.com/maps/ms?ie=UTF&msa=0&msid=202175584425752091004.0004afb57c09a69a75c5e&z=15&hl=en"
          target="_blank"
          rel="noopener noreferrer"
          class="text-green"
        >
          54.409841,23.093817
        </a>
      </p>
    </div>
    <div class="mb-3">
      <strong>{{ $t('factories.jureFactory.title') }}</strong>
      <p>{{ $t('factories.jureFactory.address') }},</p>
      <p>{{ $t('factories.jureFactory.location') }}</p>
      <p>
        {{ $t('generic.coordinates') }}:
        <i class="fas fa-map-marker-alt mr-1 text-green"></i>
        <a
          href="http://maps.google.com/maps/ms?ie=UTF&msa=0&msid=202175584425752091004.0004afb4b263a8992478b&z=15&hl=en"
          target="_blank"
          rel="noopener noreferrer"
          class="text-green"
        >
          54.767919,23.485873
        </a>
      </p>
      <p>{{ $t('factories.woodAcceptanceTime') }}: I - V 8:00-17:00</p>
    </div>
    <div class="mb-3">
      <strong>{{ $t('factories.radviliskisFactory.title') }}</strong>
      <p>{{ $t('factories.radviliskisFactory.address') }},</p>
      <p>{{ $t('factories.radviliskisFactory.location') }}</p>
      <p>
        {{ $t('generic.coordinates') }}:
        <i class="fas fa-map-marker-alt mr-1 text-green"></i>
        <a
          href="https://mapsengine.google.com/map/viewer?mid=zLULJGrDD0Tw.k_JKW87O9qds&z=15&hl=en"
          target="_blank"
          class="text-green"
          rel="noopener noreferrer"
        >
          55.820092,23.519006
        </a>
      </p>
      <p>{{ $t('factories.woodAcceptanceTime') }}: I - V 6:00-19:00</p>
    </div>
    <div class="mb-3">
      <strong>{{ $t('factories.marijampoleFactory.title') }}</strong>
      <p>{{ $t('factories.marijampoleFactory.address') }},</p>
      <p>{{ $t('factories.marijampoleFactory.location') }}</p>
      <p>
        {{ $t('generic.coordinates') }}:
        <i class="fas fa-map-marker-alt mr-1 text-green"></i>
        <a
          href="https://goo.gl/maps/LonCHWFo25amZC4H9"
          rel="noopener noreferrer"
          target="_blank"
          class="text-green"
        >
          54.586296,23.412613
        </a>
      </p>
      <p>{{ $t('factories.woodAcceptanceTime') }}: I - V 6:00-21:00</p>
    </div>
    <div v-if="$root.isLVA" class="mb-3">
      <strong>{{ $t('factories.iksķilesFactory.title') }}</strong>
      <p>{{ $t('factories.iksķilesFactory.address') }},</p>
      <p>{{ $t('factories.iksķilesFactory.location') }}</p>
      <p>
        {{ $t('generic.coordinates') }}:
        <i class="fas fa-map-marker-alt mr-1 text-green"></i>
        <a
          href="https://maps.app.goo.gl/4dDUfAkd8okBWLL8A"
          rel="noopener noreferrer"
          target="_blank"
          class="text-green"
        >
          56.847263, 24.507117
        </a>
      </p>
      <p>{{ $t('factories.woodAcceptanceTime') }}: I - V 8:00-17:00</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FactoriesList',
};
</script>
