<template>
  <div class="district-p-l">
    <div class="drawer-nav__header">
      <div class="d-flex align-items-center">
        <img src="/images/svg/measure.svg" alt="measure" />
        <h3>{{ $t('woodDefects.title') }}</h3>
      </div>
    </div>

    <div v-for="[key, defect] in Object.entries(defects)" :key="key">
      <div class="drawer-nav__type">
        <h4>{{ $t(`woodDefects.defects.${key}`) }}</h4>
      </div>
      <div class="drawer-nav__text">
        <div class="d-flex defect-wrapper">
          <div
            v-for="(src, index) in defect.images"
            :key="src"
            class="d-inline"
            @click="$emit('showImage', { index, images: defect.images })"
          >
            <img
              :src="src"
              class="defect cursor-pointer"
              :alt="`${$t(`woodDefects.defects.${key}`)}: ${index + 1}`"
            />
          </div>
        </div>

        <p
          v-if="defect.explanation"
          class="mb-3"
          v-html="$t(`woodDefects.defects.${defect.explanation}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WoodDefects',
  data() {
    return {
      defects: {
        hardRot: {
          images: ['/images/defects/kietasis-puvinys-1.jpg'],
        },
        internalSoftRot: {
          images: [
            '/images/defects/minkstasis-puvinys-1.jpg',
            '/images/defects/minkstasis-puvinys-2.jpg',
          ],
        },
        rottingBranch: {
          images: ['/images/defects/puvanti-saka-1.jpg', '/images/defects/puvanti-saka-2.jpg'],
        },
        externalSoftRot: {
          images: ['/images/defects/isorinis-puvinys.jpg'],
        },
        coreRot: {
          images: ['/images/defects/serdies-puvinys.jpg'],
        },
        trees: {
          images: ['/images/defects/dreves-1.jpg', '/images/defects/dreves-2.jpg'],
        },
        tears: {
          images: [
            '/images/defects/plysimai-skilimai-1.jpg',
            '/images/defects/plysimai-skilimai-2.jpg',
          ],
        },
        doubleBifurcation: {
          images: [
            '/images/defects/dvigubas-issisakojimas-1.jpg',
            '/images/defects/dvigubas-issisakojimas-2.png',
          ],
        },
        mechanicalDamage: {
          images: [
            '/images/defects/mechaniniai-pazeidimai-1.jpg',
            '/images/defects/mechaniniai-pazeidimai-2.jpg',
            '/images/defects/mechaniniai-pazeidimai-4.jpg',
          ],
        },
        woodBeards: {
          images: [
            '/images/defects/medienos-barzdos-1.jpg',
            '/images/defects/medienos-barzdos-2.jpg',
            '/images/defects/medienos-barzdos-3.jpg',
          ],
          explanation: 'woodBeardsExplanation',
        },
        pruning: {
          images: [
            '/images/defects/genejimas-1.jpg',
            '/images/defects/genejimas-2.jpg',
            '/images/defects/genejimas-3.jpg',
            '/images/defects/genejimas-4.jpg',
          ],
          explanation: 'pruningExplanation',
        },
        singleCurvature: {
          images: ['/images/defects/viengubas-kreivumas.jpg'],
          explanation: 'singleCurvatureExplanation',
        },
        complexCurvature: {
          images: ['/images/defects/sudetingasis-kreivumas.jpg'],
        },
        discoloration: {
          images: [
            '/images/defects/nusispalvinimas-1.jpg',
            '/images/defects/nusispalvinimas-2.jpg',
          ],
        },
        oldProductionLogs: {
          images: [
            '/images/defects/senos-gamybos-rastai-1.jpg',
            '/images/defects/senos-gamybos-rastai-2.jpg',
          ],
        },
        rockSediments: {
          images: [
            '/images/defects/uolienu-nuosedos-1.jpg',
            '/images/defects/uolienu-nuosedos-2.jpg',
          ],
        },
      },
    };
  },
};
</script>
