var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "district-p-l" },
    [
      _c("div", { staticClass: "drawer-nav__header" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("img", { attrs: { src: "/images/svg/mail.svg", alt: "mail" } }),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("generic.contacts")))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("generic.factoriesTitle")))]),
      ]),
      _vm._v(" "),
      _c("factories-list"),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("contacts.contactPersons")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-nav__contact" },
        _vm._l(_vm.contacts[_vm.$root.siteLanguageKey], function (contact) {
          return _c("div", { key: contact.name, staticClass: "d-flex mb-2" }, [
            contact.avatarUrl
              ? _c("div", [
                  _c("img", {
                    staticClass: "avatar",
                    attrs: {
                      src: `/images/${contact.avatarUrl}`,
                      alt: contact.name,
                    },
                  }),
                ])
              : _c("div", { staticClass: "avatar" }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-column ml-3 flex" }, [
              _c("div", { staticClass: "worker" }, [
                _vm._v("\n          " + _vm._s(contact.name) + "\n        "),
              ]),
              _vm._v(" "),
              contact.position
                ? _c("p", { staticClass: "position" }, [
                    _vm._v(
                      "\n          " + _vm._s(contact.position) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  { attrs: { href: `tel:${contact.phone.replace(/ /g, "")}` } },
                  [_c("span", [_vm._v(_vm._s(contact.phone))])]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("a", { attrs: { href: `mailto:${contact.email}` } }, [
                  _c("span", [_vm._v(_vm._s(contact.email))]),
                ]),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("contacts.requisites")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__text" }, [
        _c("div", { staticClass: "mb-3" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("contacts.title")))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("labels.address")) + ":")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("generic.juodeliaiAddress.street")) + ","),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("generic.juodeliaiAddress.city")) + ","),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("generic.juodeliaiAddress.lithuania"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c("p", [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("labels.emailShort")) + ":\n        "
            ),
            _c("a", { attrs: { href: `mailto:${_vm.email}` } }, [
              _c("span", [_vm._v(_vm._s(_vm.email))]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("labels.website")) + ":\n        "
            ),
            _c("a", { attrs: { href: `https://${_vm.siteUrl}` } }, [
              _c("span", [_vm._v(_vm._s(_vm.siteUrl))]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }