<template>
  <div class="district-p-l">
    <div class="drawer-nav__header">
      <div class="d-flex align-items-center">
        <img src="/images/svg/measure.svg" alt="measure" />
        <h3>{{ $t('deliveryRequirements.title') }}</h3>
      </div>
    </div>

    <div class="drawer-nav__text">
      <div v-for="requirement in requirements" :key="requirement" class="mb-3">
        <p>{{ $t(`deliveryRequirements.${requirement}`) }}:</p>
        <ul>
          <li
            v-for="translation in $t(`deliveryRequirements.${woodType}.${requirement}`)"
            :key="`${requirement}-${translation}`"
          >
            <span v-html="translation" />
          </li>
        </ul>
      </div>
    </div>

    <factories />
  </div>
</template>

<script>
import Factories from './Factories.vue';

export default {
  name: 'DeliveryRequirements',
  components: {
    Factories,
  },
  props: {
    woodType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      requirements: ['dap', 'exw', 'notCertified'],
    };
  },
};
</script>

<style scoped></style>
