var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "drawer-nav",
          class: [{ "is-open": _vm.isOpen }, _vm.customClass],
          attrs: { id: _vm.customId },
        },
        [
          _c("div", { staticClass: "drawer-nav__close text-right" }, [
            _c("i", { staticClass: "fas fa-times", on: { click: _vm.close } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "drawer-nav__wrapper" }, [
            _c("div", { staticClass: "w-100" }, [_vm._t("content")], 2),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._t("additional-content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }