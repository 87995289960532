<template>
  <div v-on-clickaway="close">
    <div :id="customId" class="drawer-nav" :class="[{ 'is-open': isOpen }, customClass]">
      <div class="drawer-nav__close text-right">
        <i class="fas fa-times" @click="close"></i>
      </div>
      <div class="drawer-nav__wrapper">
        <div class="w-100">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
    <slot name="additional-content"></slot>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
      default: null,
    },
    customId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    open: {
      handler(val) {
        this.isOpen = val;
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit('closeSidebar');
    },
  },
};
</script>

<style scoped></style>
