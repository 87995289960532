var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.district.materials, function ({ assortment, title }) {
      return _c(
        "div",
        {
          key: assortment,
          staticClass: "grid-container drawer-nav__info-row position-relative",
        },
        [
          _vm.districtAssortmentsMap[assortment].newOne
            ? [
                _vm.minimal
                  ? _c("i", { staticClass: "fas fa-circle new-one-dot" })
                  : _c("div", { staticClass: "new-one" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("generic.newOne")) +
                          "\n      "
                      ),
                    ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "grid-item grid-item-50",
              class: _vm.minimal ? "text-center" : "ml-1 position-relative",
            },
            [_c("strong", [_vm._v(_vm._s(_vm._f("separateMeters")(title)))])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "grid-item grid-item-25 text-center d-flex align-items-center",
            },
            [
              _c("span", { staticClass: "price" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.districtAssortmentsMap[assortment]
                        .delayedPaymentPrice || "-"
                    ) +
                    " €/m"
                ),
                _c("sup", [_vm._v("3")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "grid-item grid-item-25 text-center d-flex align-items-center",
            },
            [
              _c("span", { staticClass: "price" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.districtAssortmentsMap[assortment]
                        .instantPaymentPrice || "-"
                    ) +
                    " €/m"
                ),
                _c("sup", [_vm._v("3")]),
              ]),
            ]
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }