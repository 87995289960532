<template>
  <header class="navbar">
    <div class="d-flex align-items-center">
      <router-link :to="{ name: 'home' }" tag="a" class="navbar-brand">
        <img src="/logo.svg" alt="Juodeliai" width="85" />
      </router-link>
      <div class="d-lg-block d-none ml-3">
        <h1>{{ $t('generic.siteTitle') }}</h1>
      </div>
    </div>
    <nav class="d-md-flex flex-row d-none">
      <router-link v-if="showDapLink" :to="{ name: 'dap' }" tag="button" class="btn btn-nav-link">
        <span>{{ $t('generic.dap') }}</span>
      </router-link>
      <router-link v-if="showExwLink" :to="{ name: 'exw' }" tag="button" class="btn btn-nav-link">
        <span>{{ $t('generic.exw') }}</span>
      </router-link>
      <router-link v-if="showMainLink" :to="{ name: 'home' }" tag="button" class="btn btn-nav-link">
        <span>{{ $t('generic.woodPurchase') }}</span>
      </router-link>

      <ul class="d-flex">
        <li>
          <div class="position-relative">
            <button
              class="btn btn-nav-link"
              type="button"
              :class="{ 'active-btn': activeSidebar === 'quality' }"
              @click.stop="makeActiveMenu('quality')"
            >
              {{ $t('generic.qualityRequirements') }}
            </button>
            <ul v-if="showQualityMenu" v-on-clickaway="close" class="user-menu">
              <li
                v-for="action in userNavActions"
                :key="action.id"
                class="position-relative"
                :class="{ 'menu-dropdown': !!action.nestedMenuItems }"
              >
                <div v-if="action.nestedMenuItems" class="btn btn-dropdown">
                  <i class="text-center action-icon" :class="action.icon"></i>
                  {{ $t(`navbar.${action.titleKey}`) }}
                  <i class="fas fa-chevron-right ml-auto action-icon"></i>
                </div>
                <button
                  v-else
                  type="button"
                  class="btn btn-dropdown"
                  @click.stop="$emit(action.id)"
                >
                  <i class="text-center action-icon" :class="action.icon"></i>
                  {{ $t(`navbar.${action.titleKey}`) }}
                </button>

                <ul v-if="action.nestedMenuItems" class="nested-menu">
                  <li v-for="{ translationKey, id } in action.nestedMenuItems" :key="id">
                    <button
                      type="button"
                      class="btn btn-dropdown"
                      @click.stop="$emit(action.id, id)"
                    >
                      {{ $t(translationKey) }}
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <button
            class="btn btn-nav-link"
            type="button"
            :class="{ 'active-btn': activeSidebar === 'contacts' }"
            @click.stop="$emit('showContacts')"
          >
            {{ $t('generic.contacts') }}
          </button>
        </li>

        <li>
          <router-link v-if="!isUser" :to="{ name: 'login' }" tag="button" class="btn btn-primary">
            <i class="fas fa-sign-in-alt mr-2"></i>
            <span>{{ $t('generic.login') }}</span>
          </router-link>
        </li>
      </ul>

      <div v-if="isUser" class="user-icon">
        <button class="btn btn-nav-link" type="button" @click.stop="makeActiveMenu('user')">
          <i class="far fa-user-circle cursor-pointer"></i>
        </button>
        <div v-if="showUserMenu" v-on-clickaway="close" class="user-menu">
          <button v-if="isAdmin" type="button" class="btn btn-dropdown" @click="redirectToBO">
            <i class="fas fa-user-lock mr-2"></i>
            <span>Backoffice</span>
          </button>
          <router-link :to="{ name: 'profile' }" tag="button" class="btn btn-dropdown">
            <i class="fas fa-user mr-2"></i>
            <span>{{ $t('navbar.profile') }}</span>
          </router-link>

          <button v-if="isUser" type="button" class="btn btn-dropdown" @click="logout">
            <i class="fas fa-sign-out-alt mr-2"></i>
            {{ $t('navbar.logout') }}
          </button>
        </div>
      </div>
    </nav>
    <div class="d-md-none ml-auto flex-row d-flex">
      <i class="fas fa-bars cursor-pointer" @click.stop="showMobileMenu = !showMobileMenu"></i>
    </div>
    <aside
      v-on-clickaway="hideMobileMenu"
      class="drawer-nav drawer-nav--left"
      :class="{ 'is-open': showMobileMenu }"
    >
      <div class="text-center">
        <router-link :to="{ name: 'home' }" tag="a" class="navbar-brand">
          <img src="/logo.svg" alt="Juodeliai" width="85" />
        </router-link>
      </div>
      <div class="text-center">
        <h5>{{ $t('generic.siteTitle') }}</h5>
      </div>
      <div class="drawer-nav__wrapper">
        <div class="w-100">
          <hr />
          <div class="drawer-nav__nav-items">
            <router-link
              v-if="showDapLink"
              :to="{ name: 'dap' }"
              tag="button"
              class="btn btn-nav-link"
            >
              <span>{{ $t('generic.dap') }}</span>
            </router-link>
            <router-link
              v-if="showExwLink"
              :to="{ name: 'exw' }"
              tag="button"
              class="btn btn-nav-link"
            >
              <span>{{ $t('generic.exw') }}</span>
            </router-link>
            <router-link
              v-if="showMainLink"
              :to="{ name: 'home' }"
              tag="button"
              class="btn btn-nav-link"
            >
              <span>{{ $t('generic.woodPurchase') }}</span>
            </router-link>

            <ul>
              <li v-for="action in userNavActions" :key="action.id" class="pb-2">
                <div v-if="action.nestedMenuItems">
                  <strong>
                    {{
                      action.fullOnMobile
                        ? $t(`navbar.${action.titleKey}Full`)
                        : $t(`navbar.${action.titleKey}`)
                    }}
                  </strong>

                  <ul v-if="action.nestedMenuItems">
                    <li
                      v-for="{ translationKey, id } in action.nestedMenuItems"
                      :key="id"
                      class="pl-4"
                    >
                      <button
                        type="button"
                        class="btn btn-nav-link"
                        @click.stop="$emit(action.id, id)"
                      >
                        {{ $t(translationKey) }}
                      </button>
                    </li>
                  </ul>
                </div>
                <button
                  v-else
                  type="button"
                  class="btn btn-nav-link"
                  @click.stop="$emit(action.id)"
                >
                  {{
                    action.fullOnMobile
                      ? $t(`navbar.${action.titleKey}Full`)
                      : $t(`navbar.${action.titleKey}`)
                  }}
                </button>
              </li>
            </ul>

            <button type="button" class="btn btn-nav-link" @click.stop="$emit('showContacts')">
              {{ $t('generic.contacts') }}
            </button>
            <hr />
            <div v-if="isUser">
              <button v-if="isAdmin" type="button" class="btn btn-nav-link" @click="redirectToBO">
                <i class="fas fa-user-lock mr-2"></i>
                <span>Backoffice</span>
              </button>
              <router-link :to="{ name: 'profile' }" tag="button" class="btn btn-nav-link">
                <i class="fas fa-user mr-2"></i>
                <span>{{ $t('navbar.profile') }}</span>
              </router-link>
              <button type="button" class="btn btn-nav-link" @click="logout">
                <i class="fas fa-sign-out-alt mr-2"></i>
                {{ $t('navbar.logout') }}
              </button>
            </div>
            <router-link
              v-if="!isUser"
              :to="{ name: 'login' }"
              tag="button"
              class="btn btn-nav-link"
            >
              <i class="fas fa-sign-in-alt mr-2"></i>
              <span>{{ $t('generic.login') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </aside>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    closeNavbarMenus: {
      type: Boolean,
      required: true,
    },
    activeSidebar: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showUserMenu: false,
      showMobileMenu: false,
      showQualityMenu: false,
      nestedMenuShown: null,
      userNavActions: [
        {
          id: 'showConiferousQualityRequirements',
          nestedMenuItems: [
            { translationKey: 'coniferousQualityRequirements.bCClass.titleShort', id: 'bCClass' },
            { translationKey: 'coniferousQualityRequirements.dClass.titleShort', id: 'dClass' },
            { translationKey: 'coniferousQualityRequirements.dryClass.titleShort', id: 'dryClass' },
            {
              translationKey: 'coniferousQualityRequirements.pineClass.titleShort',
              id: 'pineClass',
            },
            {
              translationKey: 'coniferousQualityRequirements.leafClass.titleShort',
              id: 'leafClass',
            },
          ],
          titleKey: 'coniferousQualityRequirements',
          fullOnMobile: true,
          icon: 'fas fa-tree',
        },
        {
          id: 'showTareQualityRequirements',
          titleKey: 'tareQualityRequirements',
          fullOnMobile: true,
          icon: 'fas fa-leaf',
        },
        {
          id: 'showWoodDefectsRequirements',
          titleKey: 'woodDefectsRequirements',
          icon: 'fas fa-exclamation-circle',
        },
        {
          id: 'showDeliveryRequirements',
          titleKey: 'deliveryRequirements',
          nestedMenuItems: [
            { translationKey: 'navbar.coniferousQualityRequirements', id: 'coniferous' },
            { translationKey: 'navbar.tareQualityRequirements', id: 'tare' },
          ],
          icon: 'fas fa-truck',
        },
      ],
    };
  },
  computed: {
    isUser() {
      return this.$root.isAuth;
    },
    isAdmin() {
      return this.$root.isAdmin;
    },
    showDapLink() {
      return this.$route.name === 'exw';
    },
    showExwLink() {
      return this.$route.name === 'dap';
    },
    showMainLink() {
      return ['privacy-policy', 'cookie-policy', 'profile'].includes(this.$route.name);
    },
  },
  watch: {
    closeNavbarMenus(val) {
      if (val) {
        this.showMobileMenu = false;
        this.showUserMenu = false;
        this.showQualityMenu = false;
      }
    },
    showUserMenu(val) {
      this.$emit('toggleNavbarMenu', !val);
    },
    showQualityMenu(val) {
      this.$emit('toggleNavbarMenu', !val);
    },
    showMobileMenu(val) {
      this.$emit('toggleNavbarMenu', !val);
    },
  },
  methods: {
    logout() {
      axios.post('/logout').then(() => {
        this.$root.isAuth = false;
        this.$root.isAdmin = false;
        localStorage.removeItem('wua');
        window.location.href = '/';
      });
    },
    redirectToBO() {
      window.location.href = '/admin';
    },
    close() {
      this.showUserMenu = false;
      this.showQualityMenu = false;
    },
    hideMobileMenu() {
      this.showMobileMenu = false;
    },
    makeActiveMenu(type) {
      if (type === 'quality') {
        this.showUserMenu = false;
        this.showQualityMenu = !this.showQualityMenu;
      }
      if (type === 'user') {
        this.showQualityMenu = false;
        this.showUserMenu = !this.showUserMenu;
      }
    },
  },
};
</script>

<style scoped></style>
