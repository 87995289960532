var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column mh-100",
      class: {
        "map-bg": _vm.showMap,
        "lt-map": _vm.$root.isLTU,
        "lv-map": _vm.$root.isLVA,
        "mobile-map": _vm.mobileMap,
        backdrop: _vm.showSuggestionModal,
      },
    },
    [
      _c("div", { staticClass: "bg-white z-index-1" }, [
        _c(
          "div",
          { staticClass: "container container-nav" },
          [
            _c("navbar", {
              attrs: {
                "active-sidebar": _vm.getActiveSidebar,
                "close-navbar-menus": _vm.closeNavbarMenus,
              },
              on: {
                toggleNavbarMenu: function ($event) {
                  _vm.closeNavbarMenus = $event
                },
                showConiferousQualityRequirements: function ($event) {
                  return _vm.toggleConiferousQualityRequirements($event)
                },
                showTareQualityRequirements: function ($event) {
                  return _vm.toggleSidebar("tareQualityRequirements")
                },
                showWoodDefectsRequirements: function ($event) {
                  return _vm.toggleSidebar("woodDefects")
                },
                showDeliveryRequirements: function ($event) {
                  return _vm.toggleDeliveryRequirements($event)
                },
                showContacts: function ($event) {
                  return _vm.toggleSidebar("contacts")
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container py-3" },
        [
          _c(
            "div",
            {
              staticClass: "disclaimer-wrapper",
              class:
                _vm.$route.name === "home" ? "d-block" : "d-md-block d-none",
            },
            [_c("important-disclaimers")],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _c("router-view", {
                attrs: {
                  "show-district": _vm.sidebarShown === "district",
                  "district-assortments": _vm.districtAssortments || [],
                },
                on: {
                  showDistrict: function ($event) {
                    return _vm.toggleSidebar("sidebar")
                  },
                  getCoordinates: _vm.getUserCoordinates,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("sidebar", {
            key: "sidebar-coniferous-quality-requirements",
            attrs: {
              open: _vm.sidebarShown === "coniferousQualityRequirements",
            },
            on: {
              closeSidebar: function ($event) {
                return _vm.closeSidebar("coniferousQualityRequirements")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _vm.coniferousQualityRequirementsType
                      ? _c("coniferous-quality-requirements", {
                          attrs: {
                            "requirement-type":
                              _vm.coniferousQualityRequirementsType,
                          },
                          on: {
                            showWoodDefectsRequirements: function ($event) {
                              return _vm.toggleSidebar("woodDefects")
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("sidebar", {
            key: "sidebar-tare-quality-requirements",
            attrs: { open: _vm.sidebarShown === "tareQualityRequirements" },
            on: {
              closeSidebar: function ($event) {
                return _vm.closeSidebar("tareQualityRequirements")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("tare-quality-requirements", {
                      on: {
                        showWoodDefectsRequirements: function ($event) {
                          return _vm.toggleSidebar("woodDefects")
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("sidebar", {
            key: "sidebar-wood-defects",
            attrs: { open: _vm.sidebarShown === "woodDefects" },
            on: {
              closeSidebar: function ($event) {
                return _vm.closeSidebar("woodDefects")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("wood-defects", { on: { showImage: _vm.showGallery } }),
                  ]
                },
                proxy: true,
              },
              {
                key: "additional-content",
                fn: function () {
                  return [
                    _c("vue-easy-lightbox", {
                      attrs: {
                        visible: _vm.galleryShown,
                        imgs: _vm.galleryImages,
                        index: _vm.imageIndex,
                      },
                      on: { hide: _vm.hideGallery },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("sidebar", {
            key: "sidebar-contacts",
            attrs: { open: _vm.sidebarShown === "contacts" },
            on: {
              closeSidebar: function ($event) {
                return _vm.closeSidebar("contacts")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [_c("contacts")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("sidebar", {
            key: "sidebar-delivery-requirements",
            attrs: { open: _vm.sidebarShown === "deliveryRequirements" },
            on: {
              closeSidebar: function ($event) {
                return _vm.closeSidebar("deliveryRequirements")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _vm.deliveryRequirementsType
                      ? _c("delivery-requirements", {
                          attrs: { "wood-type": _vm.deliveryRequirementsType },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "appear" } },
            [
              _vm.showSuggestionModal && _vm.districtAssortments
                ? _c("modal", {
                    attrs: {
                      "district-assortments": _vm.districtAssortments,
                      district: _vm.suggestedDistrict,
                    },
                    on: {
                      close: function ($event) {
                        _vm.showSuggestionModal = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("site-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }