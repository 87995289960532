<template>
  <div
    class="d-flex flex-column mh-100"
    :class="{
      'map-bg': showMap,
      'lt-map': $root.isLTU,
      'lv-map': $root.isLVA,
      'mobile-map': mobileMap,
      backdrop: showSuggestionModal,
    }"
  >
    <div class="bg-white z-index-1">
      <div class="container container-nav">
        <navbar
          :active-sidebar="getActiveSidebar"
          :close-navbar-menus="closeNavbarMenus"
          @toggleNavbarMenu="closeNavbarMenus = $event"
          @showConiferousQualityRequirements="toggleConiferousQualityRequirements($event)"
          @showTareQualityRequirements="toggleSidebar('tareQualityRequirements')"
          @showWoodDefectsRequirements="toggleSidebar('woodDefects')"
          @showDeliveryRequirements="toggleDeliveryRequirements($event)"
          @showContacts="toggleSidebar('contacts')"
        />
      </div>
    </div>
    <div class="container py-3">
      <div
        class="disclaimer-wrapper"
        :class="$route.name === 'home' ? 'd-block' : 'd-md-block d-none'"
      >
        <important-disclaimers />
      </div>
      <transition name="fade" mode="out-in">
        <router-view
          :show-district="sidebarShown === 'district'"
          :district-assortments="districtAssortments || []"
          @showDistrict="toggleSidebar('sidebar')"
          @getCoordinates="getUserCoordinates"
        />
      </transition>
      <sidebar
        key="sidebar-coniferous-quality-requirements"
        :open="sidebarShown === 'coniferousQualityRequirements'"
        @closeSidebar="closeSidebar('coniferousQualityRequirements')"
      >
        <template #content>
          <coniferous-quality-requirements
            v-if="coniferousQualityRequirementsType"
            :requirement-type="coniferousQualityRequirementsType"
            @showWoodDefectsRequirements="toggleSidebar('woodDefects')"
          />
        </template>
      </sidebar>
      <sidebar
        key="sidebar-tare-quality-requirements"
        :open="sidebarShown === 'tareQualityRequirements'"
        @closeSidebar="closeSidebar('tareQualityRequirements')"
      >
        <template #content>
          <tare-quality-requirements @showWoodDefectsRequirements="toggleSidebar('woodDefects')" />
        </template>
      </sidebar>
      <sidebar
        key="sidebar-wood-defects"
        :open="sidebarShown === 'woodDefects'"
        @closeSidebar="closeSidebar('woodDefects')"
      >
        <template #content>
          <wood-defects @showImage="showGallery" />
        </template>
        <template #additional-content>
          <vue-easy-lightbox
            :visible="galleryShown"
            :imgs="galleryImages"
            :index="imageIndex"
            @hide="hideGallery"
          />
        </template>
      </sidebar>
      <sidebar
        key="sidebar-contacts"
        :open="sidebarShown === 'contacts'"
        @closeSidebar="closeSidebar('contacts')"
      >
        <template #content>
          <contacts />
        </template>
      </sidebar>
      <sidebar
        key="sidebar-delivery-requirements"
        :open="sidebarShown === 'deliveryRequirements'"
        @closeSidebar="closeSidebar('deliveryRequirements')"
      >
        <template #content>
          <delivery-requirements
            v-if="deliveryRequirementsType"
            :wood-type="deliveryRequirementsType"
          />
        </template>
      </sidebar>
      <transition name="appear">
        <modal
          v-if="showSuggestionModal && districtAssortments"
          :district-assortments="districtAssortments"
          :district="suggestedDistrict"
          @close="showSuggestionModal = false"
        />
      </transition>
    </div>
    <site-footer />
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import Navbar from './layout/Navbar.vue';
import SiteFooter from './layout/SiteFooter.vue';
import Sidebar from './layout/sidebars/Sidebar.vue';
import ConiferousQualityRequirements from './layout/sidebars/ConiferousQualityRequirements.vue';
import TareQualityRequirements from './layout/sidebars/TareQualityRequirements.vue';
import WoodDefects from './layout/sidebars/WoodDefects.vue';
import DeliveryRequirements from './layout/sidebars/DeliveryRequirements.vue';
import Contacts from './layout/sidebars/Contacts.vue';
import Modal from './layout/Modal.vue';
import ImportantDisclaimers from './ImportantDisclaimers.vue';

export default {
  name: 'App',
  components: {
    ImportantDisclaimers,
    ConiferousQualityRequirements,
    TareQualityRequirements,
    WoodDefects,
    Sidebar,
    SiteFooter,
    Navbar,
    Contacts,
    DeliveryRequirements,
    VueEasyLightbox,
    Modal,
  },
  data() {
    return {
      showMap: false,
      mapRoutes: [
        'home',
        'login',
        'signup',
        'reset-password',
        'new-password-submission',
        'profile',
        'privacy-policy',
        'cookie-policy',
      ],
      mobileMap: false,
      windowWidth: window.innerWidth,
      closeNavbarMenus: false,
      showSuggestionModal: false,
      suggestedDistrict: null,
      showDisclaimer: true,
      galleryShown: false,
      galleryImages: [],
      imageIndex: 0,
      districtAssortments: null,
      sidebarShown: null,
      coniferousQualityRequirementsType: null,
      deliveryRequirementsType: null,
    };
  },
  computed: {
    getActiveSidebar() {
      if (
        ['coniferousQualityRequirements', 'tareQualityRequirements', 'woodDefects'].includes(
          this.sidebarShown,
        )
      ) {
        return 'quality';
      }
      if (this.sidebarShown === 'contacts') {
        return 'contacts';
      }
      return null;
    },
  },
  watch: {
    $route(to) {
      this.showMap = this.mapRoutes.includes(to.name) || this.$route.meta.errorPage === 404;
      this.closeNavbarMenus = true;
    },
    windowWidth(e) {
      this.mobileMap = e <= 767;
    },
  },
  created() {
    this.showMap = this.mapRoutes.includes(this.$route.name) || this.$route.meta.errorPage === 404;
    axios.defaults.baseURL = apiUrl;
    axios.defaults.withCredentials = true;
    this.$root.isLTU = siteLang === 'LTU';
    this.$root.isLVA = siteLang === 'LVA';
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.mobileMap = this.windowWidth <= 767;
    if (
      localStorage.getItem('wua') &&
      typeof JSON.parse(localStorage.getItem('wua')) === 'number'
    ) {
      this.checkIfLoggedIn();
    }

    this.loadDistrictAssortments();
  },
  methods: {
    loadDistrictAssortments() {
      axios.get('/public/district-assortment').then((resp) => {
        this.districtAssortments = resp.data.districtAssortments;
      });
    },
    checkIfLoggedIn() {
      axios
        .get('/me')
        .then((resp) => {
          this.$root.isAuth = true;
          this.$root.isAdmin = resp.data.user.isAdmin;
        })
        .catch(() => {
          localStorage.removeItem('wua');
          this.$root.isAuth = false;
          this.$root.isAdmin = false;
        });
    },
    closeSidebar(sidebarToClose) {
      if (sidebarToClose !== this.sidebarShown) {
        return;
      }

      this.sidebarShown = null;
      this.coniferousQualityRequirementsType = null;
    },
    toggleConiferousQualityRequirements(requirementType) {
      this.toggleSidebar('coniferousQualityRequirements');

      this.coniferousQualityRequirementsType = requirementType;
    },
    toggleDeliveryRequirements(woodType) {
      this.toggleSidebar('deliveryRequirements');

      this.deliveryRequirementsType = woodType;
    },
    toggleSidebar(sidebar) {
      this.sidebarShown = null;

      setTimeout(() => {
        this.sidebarShown = sidebar;
      }, 50);
    },
    getUserCoordinates() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showSuggestion);
      }
    },
    showSuggestion(position) {
      this.getUserDistrict(position).then((title) => {
        this.getDistrictInfo(title);
      });
    },
    getUserDistrict(position) {
      return new Promise((resolve, reject) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        if (lat && long) {
          const prox = `${lat.toString()},${long.toString()},5000`;
          axios
            .get('https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json', {
              params: {
                prox,
                mode: 'retrieveAddresses',
                maxresults: '1',
                gen: '9',
                apiKey: 'Ea6-gJCed18jAeBPEYsMb6gGxkq5Viipec5QIm1Iy-0',
              },
              withCredentials: false,
            })
            .then((resp) => {
              resolve(resp.data.Response.View[0].Result[0].Location.Address.County);
            })
            .catch(() => {
              reject();
            });
        }
      });
    },
    getDistrictInfo(title) {
      return new Promise((resolve, reject) => {
        axios
          .get('/public/district', {
            params: {
              title,
            },
          })
          .then((resp) => {
            this.suggestedDistrict = resp.data.district;

            if (this.suggestedDistrict?.districtInformations[0]?.materials.length) {
              this.showSuggestionModal = true;
            }

            sessionStorage.setItem('district-suggestion', this.suggestedDistrict.title);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    showGallery({ index, images }) {
      this.imageIndex = index;
      this.galleryShown = true;
      this.galleryImages = images;
    },
    hideGallery() {
      this.galleryShown = false;
      this.galleryImages = [];
    },
  },
};
</script>

<style scoped></style>
