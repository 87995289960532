<template>
  <div class="district-p-l">
    <div class="drawer-nav__header">
      <div class="d-flex align-items-center">
        <img src="/images/svg/measure.svg" alt="measure" />
        <h3>{{ $t(`coniferousQualityRequirements.${requirementType}.title`) }}</h3>
      </div>
    </div>

    <div class="drawer-nav__text">
      <div class="mb-3">
        <div v-for="sawLogsAbbreviation in requirements.abbreviations" :key="sawLogsAbbreviation">
          <p v-html="$t(`qualityRequirements.abbreviations.${sawLogsAbbreviation}`)" />
        </div>
        <div>
          <strong>{{ $t('qualityRequirements.speciesLabel') }}: </strong>
          <p
            class="d-inline-block"
            v-html="mergeText(requirements.species, 'qualityRequirements.species')"
          />
        </div>
        <div v-if="requirements.sizeAbbreviations">
          <p
            class="d-inline-block"
            v-html="
              mergeText(
                requirements.sizeAbbreviations,
                'coniferousQualityRequirements.general.sizeAbbreviations',
              )
            "
          />
        </div>
      </div>

      <div
        v-for="otherRequirement in requirements.otherRequirements"
        :key="otherRequirement"
        class="mb-3"
      >
        <strong v-html="$t(`qualityRequirements.${otherRequirement}`)" />
        <ul>
          <li
            v-for="translation in $t(
              `coniferousQualityRequirements.${requirementType}.${otherRequirement}List`,
            )"
            :key="translation"
          >
            <span v-html="translation" />
          </li>
        </ul>
      </div>

      <div class="mb-3">
        <strong v-html="$t(`qualityRequirements.forbidden`)" />

        <ul>
          <li v-for="translation in Object.values(forbiddenList)" :key="translation">
            <span v-html="translation" />
          </li>
        </ul>
      </div>

      <i18n path="qualityRequirements.moreInfo" tag="strong">
        <button
          type="button"
          class="btn-link"
          place="link"
          @click="$emit('showWoodDefectsRequirements')"
        >
          {{ $t('qualityRequirements.moreInfoLink') }}
        </button>
      </i18n>
    </div>
    <factories />
  </div>
</template>

<script>
import { join, omit } from 'lodash';
import Factories from './Factories.vue';

export default {
  name: 'ConiferousQualityRequirements',
  components: {
    Factories,
  },
  props: {
    requirementType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      config: {
        bCClass: {
          abbreviations: ['sawLogs'],
          species: ['spruce', 'pine'],
          sizeAbbreviations: ['st', 'vd', 'sm'],
          otherRequirements: [
            'diameter',
            'curvature',
            'branches',
            'condescension',
            'lengths',
            'measurement',
          ],
          forbiddenExcluded: ['softRots'],
        },
        dClass: {
          abbreviations: ['sawLogs'],
          species: ['spruce', 'pine'],
          otherRequirements: ['diameter', 'curvature', 'condescension', 'lengths', 'measurement'],
          forbiddenExcluded: ['hardAndSoftRots'],
        },
        dryClass: {
          abbreviations: ['sawLogs'],
          species: ['spruce', 'pine'],
          otherRequirements: ['diameter', 'curvature', 'condescension', 'lengths', 'measurement'],
          forbiddenExcluded: ['hardAndSoftRots', 'dry'],
        },
        pineClass: {
          abbreviations: ['sawLogs'],
          species: ['pine'],
          sizeAbbreviations: ['st', 'vd', 'smExtended', 'str'],
          otherRequirements: ['diameter', 'curvature', 'condescension', 'lengths', 'measurement'],
          forbiddenExcluded: ['softRots'],
        },
        leafClass: {
          abbreviations: ['sawLogs'],
          species: ['birch', 'aspen', 'blackAlder', 'linde', 'poplar'],
          otherRequirements: ['diameter', 'curvature', 'condescension', 'lengths', 'measurement'],
          forbiddenExcluded: ['hardAndSoftRots'],
        },
      },
    };
  },
  computed: {
    requirements() {
      return this.config[this.requirementType];
    },
    forbiddenList() {
      const forbiddenList = this.$t(`qualityRequirements.forbiddenList`);
      const excludedList = this.requirements.forbiddenExcluded || [];

      return omit(forbiddenList, excludedList);
    },
  },
  methods: {
    mergeText(list, translationKey) {
      const translationList = list.map((listItem) => this.$t(`${translationKey}.${listItem}`));

      return join(translationList, ', ');
    },
  },
};
</script>

<style scoped></style>
