<template>
  <div :class="compact && 'mb-3'">
    <div v-for="banner in showableBanners" :key="banner.id">
      <div
        v-if="visibleBanners.includes(banner.id)"
        class="position-relative mb-1 px-md-3 px-0"
        :class="compact ? 'row' : 'container'"
      >
        <div class="d-flex mb-0 alert alert-danger flex-row flex-nowrap align-items-stretch w-100">
          <div class="alert__state-icon p-3">
            <i class="fas fa-info"></i>
          </div>
          <div class="alert__message p-3 d-flex align-items-center border-radius-0">
            <p v-html="banner.htmlContent"></p>
          </div>
          <div v-if="!compact" class="alert__state-icon alert__state-icon--right p-3">
            <i class="fas fa-times cursor-pointer" @click="hide(banner.id)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportantDisclaimers',
  props: {
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      visibleBanners: [],
      banners: [],
    };
  },
  computed: {
    showableBanners() {
      return ['home', 'dap', 'exw'].includes(this.$route.name) && this.banners;
    },
  },
  created() {
    this.getBanners();
  },
  methods: {
    hide(idToRemove) {
      this.visibleBanners = this.visibleBanners.filter((id) => id !== idToRemove);
    },
    getBanners() {
      axios.get('/public/banner').then(({ data }) => {
        this.banners = data.banners;
        this.visibleBanners = this.banners.map(({ id }) => id);
      });
    },
  },
};
</script>

<style scoped></style>
