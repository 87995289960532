var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _c(
          "router-link",
          {
            staticClass: "navbar-brand",
            attrs: { to: { name: "home" }, tag: "a" },
          },
          [
            _c("img", {
              attrs: { src: "/logo.svg", alt: "Juodeliai", width: "85" },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-lg-block d-none ml-3" }, [
          _c("h1", [_vm._v(_vm._s(_vm.$t("generic.siteTitle")))]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "nav",
      { staticClass: "d-md-flex flex-row d-none" },
      [
        _vm.showDapLink
          ? _c(
              "router-link",
              {
                staticClass: "btn btn-nav-link",
                attrs: { to: { name: "dap" }, tag: "button" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("generic.dap")))])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showExwLink
          ? _c(
              "router-link",
              {
                staticClass: "btn btn-nav-link",
                attrs: { to: { name: "exw" }, tag: "button" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("generic.exw")))])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showMainLink
          ? _c(
              "router-link",
              {
                staticClass: "btn btn-nav-link",
                attrs: { to: { name: "home" }, tag: "button" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("generic.woodPurchase")))])]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("ul", { staticClass: "d-flex" }, [
          _c("li", [
            _c("div", { staticClass: "position-relative" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-nav-link",
                  class: { "active-btn": _vm.activeSidebar === "quality" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.makeActiveMenu("quality")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("generic.qualityRequirements")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.showQualityMenu
                ? _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.close,
                          expression: "close",
                        },
                      ],
                      staticClass: "user-menu",
                    },
                    _vm._l(_vm.userNavActions, function (action) {
                      return _c(
                        "li",
                        {
                          key: action.id,
                          staticClass: "position-relative",
                          class: { "menu-dropdown": !!action.nestedMenuItems },
                        },
                        [
                          action.nestedMenuItems
                            ? _c("div", { staticClass: "btn btn-dropdown" }, [
                                _c("i", {
                                  staticClass: "text-center action-icon",
                                  class: action.icon,
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(`navbar.${action.titleKey}`)
                                    ) +
                                    "\n                "
                                ),
                                _c("i", {
                                  staticClass:
                                    "fas fa-chevron-right ml-auto action-icon",
                                }),
                              ])
                            : _c(
                                "button",
                                {
                                  staticClass: "btn btn-dropdown",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$emit(action.id)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "text-center action-icon",
                                    class: action.icon,
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(`navbar.${action.titleKey}`)
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          action.nestedMenuItems
                            ? _c(
                                "ul",
                                { staticClass: "nested-menu" },
                                _vm._l(
                                  action.nestedMenuItems,
                                  function ({ translationKey, id }) {
                                    return _c("li", { key: id }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-dropdown",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.$emit(action.id, id)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t(translationKey)) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "button",
              {
                staticClass: "btn btn-nav-link",
                class: { "active-btn": _vm.activeSidebar === "contacts" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$emit("showContacts")
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("generic.contacts")) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              !_vm.isUser
                ? _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { to: { name: "login" }, tag: "button" },
                    },
                    [
                      _c("i", { staticClass: "fas fa-sign-in-alt mr-2" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("generic.login")))]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.isUser
          ? _c("div", { staticClass: "user-icon" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-nav-link",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.makeActiveMenu("user")
                    },
                  },
                },
                [_c("i", { staticClass: "far fa-user-circle cursor-pointer" })]
              ),
              _vm._v(" "),
              _vm.showUserMenu
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.close,
                          expression: "close",
                        },
                      ],
                      staticClass: "user-menu",
                    },
                    [
                      _vm.isAdmin
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-dropdown",
                              attrs: { type: "button" },
                              on: { click: _vm.redirectToBO },
                            },
                            [
                              _c("i", { staticClass: "fas fa-user-lock mr-2" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Backoffice")]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-dropdown",
                          attrs: { to: { name: "profile" }, tag: "button" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-user mr-2" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navbar.profile"))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isUser
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-dropdown",
                              attrs: { type: "button" },
                              on: { click: _vm.logout },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-sign-out-alt mr-2",
                              }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("navbar.logout")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-md-none ml-auto flex-row d-flex" }, [
      _c("i", {
        staticClass: "fas fa-bars cursor-pointer",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            _vm.showMobileMenu = !_vm.showMobileMenu
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "aside",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.hideMobileMenu,
            expression: "hideMobileMenu",
          },
        ],
        staticClass: "drawer-nav drawer-nav--left",
        class: { "is-open": _vm.showMobileMenu },
      },
      [
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-brand",
                attrs: { to: { name: "home" }, tag: "a" },
              },
              [
                _c("img", {
                  attrs: { src: "/logo.svg", alt: "Juodeliai", width: "85" },
                }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("generic.siteTitle")))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "drawer-nav__wrapper" }, [
          _c("div", { staticClass: "w-100" }, [
            _c("hr"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "drawer-nav__nav-items" },
              [
                _vm.showDapLink
                  ? _c(
                      "router-link",
                      {
                        staticClass: "btn btn-nav-link",
                        attrs: { to: { name: "dap" }, tag: "button" },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("generic.dap")))])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showExwLink
                  ? _c(
                      "router-link",
                      {
                        staticClass: "btn btn-nav-link",
                        attrs: { to: { name: "exw" }, tag: "button" },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("generic.exw")))])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showMainLink
                  ? _c(
                      "router-link",
                      {
                        staticClass: "btn btn-nav-link",
                        attrs: { to: { name: "home" }, tag: "button" },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("generic.woodPurchase"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.userNavActions, function (action) {
                    return _c("li", { key: action.id, staticClass: "pb-2" }, [
                      action.nestedMenuItems
                        ? _c("div", [
                            _c("strong", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    action.fullOnMobile
                                      ? _vm.$t(`navbar.${action.titleKey}Full`)
                                      : _vm.$t(`navbar.${action.titleKey}`)
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            action.nestedMenuItems
                              ? _c(
                                  "ul",
                                  _vm._l(
                                    action.nestedMenuItems,
                                    function ({ translationKey, id }) {
                                      return _c(
                                        "li",
                                        { key: id, staticClass: "pl-4" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-nav-link",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.$emit(
                                                    action.id,
                                                    id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(translationKey)
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn-nav-link",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit(action.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    action.fullOnMobile
                                      ? _vm.$t(`navbar.${action.titleKey}Full`)
                                      : _vm.$t(`navbar.${action.titleKey}`)
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-nav-link",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("showContacts")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("generic.contacts")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm.isUser
                  ? _c(
                      "div",
                      [
                        _vm.isAdmin
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-nav-link",
                                attrs: { type: "button" },
                                on: { click: _vm.redirectToBO },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-user-lock mr-2",
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Backoffice")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-nav-link",
                            attrs: { to: { name: "profile" }, tag: "button" },
                          },
                          [
                            _c("i", { staticClass: "fas fa-user mr-2" }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("navbar.profile"))),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-nav-link",
                            attrs: { type: "button" },
                            on: { click: _vm.logout },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-sign-out-alt mr-2",
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("navbar.logout")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isUser
                  ? _c(
                      "router-link",
                      {
                        staticClass: "btn btn-nav-link",
                        attrs: { to: { name: "login" }, tag: "button" },
                      },
                      [
                        _c("i", { staticClass: "fas fa-sign-in-alt mr-2" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("generic.login")))]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }