var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.compact && "mb-3" },
    _vm._l(_vm.showableBanners, function (banner) {
      return _c("div", { key: banner.id }, [
        _vm.visibleBanners.includes(banner.id)
          ? _c(
              "div",
              {
                staticClass: "position-relative mb-1 px-md-3 px-0",
                class: _vm.compact ? "row" : "container",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex mb-0 alert alert-danger flex-row flex-nowrap align-items-stretch w-100",
                  },
                  [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "alert__message p-3 d-flex align-items-center border-radius-0",
                      },
                      [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(banner.htmlContent) },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.compact
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert__state-icon alert__state-icon--right p-3",
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-times cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.hide(banner.id)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert__state-icon p-3" }, [
      _c("i", { staticClass: "fas fa-info" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }