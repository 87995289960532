var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "simple-modal",
    },
    [
      _c("div", { staticClass: "modal-content" }, [
        _c("button", {
          staticClass: "modal-close-btn",
          attrs: { type: "button" },
          on: { click: _vm.close },
        }),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.$t("suggestionModal.title")))]),
        _vm._v(" "),
        _c("h4", { staticClass: "my-4" }, [
          _vm._v("\n      " + _vm._s(_vm.district.title) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-100 overflow-y-auto" },
          _vm._l([_vm.dapMaterials, _vm.exwMaterials], function (material) {
            return _c(
              "div",
              { key: material.key, class: { "mb-3": material.key === "dap" } },
              [
                material.data && !!material.data.materials.length
                  ? [
                      _c("small", { staticClass: "text-success" }, [
                        _vm._v(_vm._s(_vm.$t(`generic.${material.key}`))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "grid-container mb-1" }, [
                        _c(
                          "div",
                          { staticClass: "grid-item grid-item-50 text-center" },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("generic.paying"))),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "grid-item grid-item-25 text-center" },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("generic.per30Days"))),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "grid-item grid-item-25 text-center" },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("generic.per7Days"))),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("assortment-list", {
                        attrs: {
                          "district-assortments": _vm.districtAssortments,
                          incoterm: material.key,
                          district: {
                            ...material.data,
                            code: _vm.district.code,
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center w-100 my-3" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.redirectToMap(material.key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("suggestionModal.more")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }