<template>
  <div class="district-p-l">
    <div class="drawer-nav__header">
      <div class="d-flex align-items-center">
        <img src="/images/svg/measure.svg" alt="measure" />
        <h3>{{ $t('tareQualityRequirements.general.title') }}</h3>
      </div>
    </div>

    <div class="drawer-nav__text">
      <div class="mb-3">
        <p>
          <strong>{{ $t('qualityRequirements.speciesLabel') }}:</strong>
          <span v-html="mergeText(species, 'qualityRequirements.species')" />
        </p>
        <p v-html="mergeText(abbreviations, 'qualityRequirements.abbreviations')" />
      </div>

      <div v-for="otherRequirement in otherRequirements" :key="otherRequirement" class="mb-3">
        <strong v-html="$t(`qualityRequirements.${otherRequirement}`)" />
        <ul>
          <li
            v-for="translation in $t(`tareQualityRequirements.${otherRequirement}List`)"
            :key="translation"
          >
            <span v-html="translation" />
          </li>
        </ul>
      </div>

      <div class="mb-3">
        <strong v-html="$t(`qualityRequirements.forbidden`)" />
        <ul>
          <li v-for="translation in Object.values(forbiddenList)" :key="translation">
            <span v-html="translation" />
          </li>
        </ul>
      </div>

      <i18n path="qualityRequirements.moreInfo" tag="strong">
        <button
          type="button"
          class="btn-link"
          place="link"
          @click="$emit('showWoodDefectsRequirements')"
        >
          {{ $t('qualityRequirements.moreInfoLink') }}
        </button>
      </i18n>
    </div>
    <factories />
  </div>
</template>

<script>
import { join, omit } from 'lodash';
import Factories from './Factories.vue';

export default {
  name: 'TareQualityRequirements',
  components: {
    Factories,
  },
  data() {
    return {
      abbreviations: ['tareLogs', 'conifersLogs', 'deciduousTrees'],
      species: ['spruce', 'pine', 'birch', 'blackAlder', 'aspen', 'linde', 'poplar', 'other'],
      otherRequirements: ['diameter', 'curvature', 'lengths', 'measurement'],
      forbiddenExcluded: ['hardAndSoftRots', 'dry'],
    };
  },
  computed: {
    forbiddenList() {
      const forbiddenList = this.$t(`qualityRequirements.forbiddenList`);

      return omit(forbiddenList, this.forbiddenExcluded);
    },
  },
  methods: {
    mergeText(list, translationKey) {
      const translationList = list.map((listItem) => this.$t(`${translationKey}.${listItem}`));

      return join(translationList, ', ');
    },
  },
};
</script>

<style scoped></style>
