var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "z-index-0 mt-md-0 mt-5" }, [
    _c("div", { staticClass: "bg-white" }, [
      _c("div", { staticClass: "container position-relative py-3" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c("div", [_vm._v("© " + _vm._s(_vm.$t("footer.title")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "position-relative" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "pl-sm-2 d-sm-inline d-block",
                    attrs: { to: { name: "privacy-policy" }, tag: "a" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("generic.privacyPolicy")) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "pl-sm-2 d-sm-inline d-block",
                    attrs: { to: { name: "cookie-policy" }, tag: "a" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("cookiePolicyPage.title")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary floating-btn floating-btn--chat",
            attrs: { type: "button" },
            on: { click: _vm.openFreshChat },
          },
          [
            _c("span", { staticClass: "d-inline-flex align-items-center" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("footer.consultationCTA")) +
                  "\n        "
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }