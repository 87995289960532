import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const Dap = () => import(/* webpackChunkName: "Dap" */ '../components/Dap.vue');
const Exw = () => import(/* webpackChunkName: "Exw" */ '../components/Exw.vue');
const Home = () => import(/* webpackChunkName: "Home" */ '../components/Home.vue');
const ErrorPage = () =>
  import(/* webpackChunkName: "ErrorPage" */ '../components/utils/ErrorPage.vue');
const Login = () => import(/* webpackChunkName: "Login" */ '../components/Login.vue');
const Signup = () => import(/* webpackChunkName: "Signup" */ '../components/Signup.vue');
const PasswordReset = () =>
  import(/* webpackChunkName: "PasswordReset" */ '../components/PasswordReset.vue');
const NewPasswordSubmission = () =>
  import(/* webpackChunkName: "NewPasswordSubmission" */ '../components/NewPasswordSubmission.vue');
const Profile = () => import(/* webpackChunkName: "Profile" */ '../components/Profile.vue');
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "PrivacyPolicy" */ '../components/PrivacyPolicy.vue');

const CookiePolicy = () =>
  import(/* webpackChunkName: "CookiePolicy" */ '../components/CookiePolicy.vue');

const ifAuthenticated = (to, from, next) => {
  if (typeof JSON.parse(localStorage.getItem('wua')) === 'number') {
    next();
    return;
  }
  next('/');
};
const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('wua')) {
    next();
    return;
  }
  next('/');
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/join',
    name: 'signup',
    component: Signup,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    props: true,
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/delivered-at-place',
    name: 'dap',
    component: Dap,
    props: true,
  },
  {
    path: '/exw',
    name: 'exw',
    component: Exw,
    props: true,
  },
  {
    path: '/password-reset/:userId/:token',
    name: 'new-password-submission',
    component: NewPasswordSubmission,
    props: true,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: CookiePolicy,
  },
  {
    path: '*',
    component: ErrorPage,
    props: true,
    meta: {
      errorPage: 404,
    },
  },
];

export default new Router({
  routes,
  mode: 'history',
});
