<template>
  <div>
    <div
      v-for="{ assortment, title } in district.materials"
      :key="assortment"
      class="grid-container drawer-nav__info-row position-relative"
    >
      <template v-if="districtAssortmentsMap[assortment].newOne">
        <i v-if="minimal" class="fas fa-circle new-one-dot"></i>
        <div v-else class="new-one">
          {{ $t('generic.newOne') }}
        </div>
      </template>

      <div
        class="grid-item grid-item-50"
        :class="minimal ? 'text-center' : 'ml-1 position-relative'"
      >
        <strong>{{ title | separateMeters }}</strong>
      </div>
      <div class="grid-item grid-item-25 text-center d-flex align-items-center">
        <span class="price">
          {{ districtAssortmentsMap[assortment].delayedPaymentPrice || '-' }} €/m<sup>3</sup>
        </span>
      </div>
      <div class="grid-item grid-item-25 text-center d-flex align-items-center">
        <span class="price">
          {{ districtAssortmentsMap[assortment].instantPaymentPrice || '-' }} €/m<sup>3</sup>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssortmentList',
  filters: {
    separateMeters(val) {
      return val.replace('m', ' m');
    },
  },
  props: {
    minimal: {
      type: Boolean,
      required: false,
      default: false,
    },
    incoterm: {
      type: String,
      required: true,
    },
    district: {
      type: Object,
      required: false,
      default: null,
    },
    districtAssortments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    districtAssortmentsMap() {
      return this.district.materials.reduce(
        (acc, data) => ({
          ...acc,
          [data.assortment]: {
            ...data,
          },
        }),
        {},
      );
    },
  },
};
</script>

<style scoped></style>
