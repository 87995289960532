var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "district-p-l" },
    [
      _c("div", { staticClass: "drawer-nav__header" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("img", {
            attrs: { src: "/images/svg/measure.svg", alt: "measure" },
          }),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("deliveryRequirements.title")))]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-nav__text" },
        _vm._l(_vm.requirements, function (requirement) {
          return _c("div", { key: requirement, staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t(`deliveryRequirements.${requirement}`)) + ":"
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(
                _vm.$t(`deliveryRequirements.${_vm.woodType}.${requirement}`),
                function (translation) {
                  return _c("li", { key: `${requirement}-${translation}` }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(translation) },
                    }),
                  ])
                }
              ),
              0
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("factories"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }