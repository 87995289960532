var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "district-p-l" },
    [
      _c("div", { staticClass: "drawer-nav__header" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("img", {
            attrs: { src: "/images/svg/measure.svg", alt: "measure" },
          }),
          _vm._v(" "),
          _c("h3", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  `coniferousQualityRequirements.${_vm.requirementType}.title`
                )
              )
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-nav__text" },
        [
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _vm._l(
                _vm.requirements.abbreviations,
                function (sawLogsAbbreviation) {
                  return _c("div", { key: sawLogsAbbreviation }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            `qualityRequirements.abbreviations.${sawLogsAbbreviation}`
                          )
                        ),
                      },
                    }),
                  ])
                }
              ),
              _vm._v(" "),
              _c("div", [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t("qualityRequirements.speciesLabel")) + ": "
                  ),
                ]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "d-inline-block",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.mergeText(
                        _vm.requirements.species,
                        "qualityRequirements.species"
                      )
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.requirements.sizeAbbreviations
                ? _c("div", [
                    _c("p", {
                      staticClass: "d-inline-block",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.mergeText(
                            _vm.requirements.sizeAbbreviations,
                            "coniferousQualityRequirements.general.sizeAbbreviations"
                          )
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm._l(
            _vm.requirements.otherRequirements,
            function (otherRequirement) {
              return _c("div", { key: otherRequirement, staticClass: "mb-3" }, [
                _c("strong", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(`qualityRequirements.${otherRequirement}`)
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(
                    _vm.$t(
                      `coniferousQualityRequirements.${_vm.requirementType}.${otherRequirement}List`
                    ),
                    function (translation) {
                      return _c("li", { key: translation }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(translation) },
                        }),
                      ])
                    }
                  ),
                  0
                ),
              ])
            }
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3" }, [
            _c("strong", {
              domProps: {
                innerHTML: _vm._s(_vm.$t(`qualityRequirements.forbidden`)),
              },
            }),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(Object.values(_vm.forbiddenList), function (translation) {
                return _c("li", { key: translation }, [
                  _c("span", { domProps: { innerHTML: _vm._s(translation) } }),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "i18n",
            { attrs: { path: "qualityRequirements.moreInfo", tag: "strong" } },
            [
              _c(
                "button",
                {
                  staticClass: "btn-link",
                  attrs: { type: "button", place: "link" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("showWoodDefectsRequirements")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("qualityRequirements.moreInfoLink")) +
                      "\n      "
                  ),
                ]
              ),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("factories"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }