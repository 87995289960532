var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "district-p-l" },
    [
      _c("div", { staticClass: "drawer-nav__header" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("img", {
            attrs: { src: "/images/svg/measure.svg", alt: "measure" },
          }),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("woodDefects.title")))]),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(Object.entries(_vm.defects), function ([key, defect]) {
        return _c("div", { key: key }, [
          _c("div", { staticClass: "drawer-nav__type" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t(`woodDefects.defects.${key}`)))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "drawer-nav__text" }, [
            _c(
              "div",
              { staticClass: "d-flex defect-wrapper" },
              _vm._l(defect.images, function (src, index) {
                return _c(
                  "div",
                  {
                    key: src,
                    staticClass: "d-inline",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("showImage", {
                          index,
                          images: defect.images,
                        })
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "defect cursor-pointer",
                      attrs: {
                        src: src,
                        alt: `${_vm.$t(`woodDefects.defects.${key}`)}: ${
                          index + 1
                        }`,
                      },
                    }),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            defect.explanation
              ? _c("p", {
                  staticClass: "mb-3",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(`woodDefects.defects.${defect.explanation}`)
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }