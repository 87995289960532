var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("generic.factoriesTitle")))]),
      ]),
      _vm._v(" "),
      _c("factories-list"),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("factories.purchaseManager")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-nav__contact" },
        _vm._l(_vm.contacts[_vm.$root.siteLanguageKey], function (contact) {
          return _c("div", { key: contact.name, staticClass: "d-flex mb-2" }, [
            contact.avatarUrl
              ? _c("div", [
                  _c("img", {
                    staticClass: "avatar",
                    attrs: {
                      src: `/images/${contact.avatarUrl}`,
                      alt: contact.name,
                    },
                  }),
                ])
              : _c("div", { staticClass: "avatar" }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-column ml-3" }, [
              _c("div", { staticClass: "worker" }, [
                _vm._v("\n          " + _vm._s(contact.name) + "\n        "),
              ]),
              _vm._v(" "),
              contact.position
                ? _c("p", { staticClass: "position" }, [
                    _vm._v(
                      "\n          " + _vm._s(contact.position) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  { attrs: { href: `tel:${contact.phone.replace(/ /g, "")}` } },
                  [_c("span", [_vm._v(_vm._s(contact.phone))])]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("a", { attrs: { href: `mailto:${contact.email}` } }, [
                  _c("span", [_vm._v(_vm._s(contact.email))]),
                ]),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__text" }, [
        _c("p", { staticClass: "font-sz-75" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("factories.disclaimer")) + "\n      "
          ),
          _c(
            "a",
            {
              attrs: {
                href: `mailto:${_vm.$root.email[_vm.$root.siteLanguageKey]}`,
              },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$root.email[_vm.$root.siteLanguageKey])),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }