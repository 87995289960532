<template>
  <div v-on-clickaway="close" class="simple-modal">
    <div class="modal-content">
      <button type="button" class="modal-close-btn" @click="close"></button>
      <h2>{{ $t('suggestionModal.title') }}</h2>
      <h4 class="my-4">
        {{ district.title }}
      </h4>
      <div class="w-100 overflow-y-auto">
        <div
          v-for="material in [dapMaterials, exwMaterials]"
          :key="material.key"
          :class="{ 'mb-3': material.key === 'dap' }"
        >
          <template v-if="material.data && !!material.data.materials.length">
            <small class="text-success">{{ $t(`generic.${material.key}`) }}</small>
            <div class="grid-container mb-1">
              <div class="grid-item grid-item-50 text-center">
                <strong>{{ $t('generic.paying') }}</strong>
              </div>
              <div class="grid-item grid-item-25 text-center">
                <strong>{{ $t('generic.per30Days') }}</strong>
              </div>
              <div class="grid-item grid-item-25 text-center">
                <strong>{{ $t('generic.per7Days') }}</strong>
              </div>
            </div>
            <assortment-list
              :district-assortments="districtAssortments"
              :incoterm="material.key"
              :district="{ ...material.data, code: district.code }"
            />
            <div class="text-center w-100 my-3">
              <button type="button" class="btn btn-primary" @click="redirectToMap(material.key)">
                {{ $t('suggestionModal.more') }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssortmentList from '../AssortmentList.vue';

export default {
  name: 'Modal',
  components: { AssortmentList },
  filters: {
    separateMeters(val) {
      return val.replace('m', ' m');
    },
  },
  props: {
    district: {
      type: Object,
      required: true,
    },
    districtAssortments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    exwMaterials() {
      return {
        data: this.district.districtInformations.find((x) => x.incoterm === 'EXW'),
        key: 'exw',
      };
    },
    dapMaterials() {
      return {
        data: this.district.districtInformations.find((x) => x.incoterm === 'DAP'),
        key: 'dap',
      };
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    redirectToMap(incoterm) {
      this.$router.push({ name: incoterm, params: { loadDistrict: this.district.code } });
      this.close();
    },
  },
};
</script>

<style scoped></style>
